{
  "navigation": {
    "menu": "Menu",
    "masterData": "Data Master",
    "transactional": "Transaksi",
    "report": "Laporan",
    "home": "Beranda",
    "showcase": "Show Case",
    "dashboard": "Dashboard",
    "storeProfile": "Profil Toko",
    "stores": "Toko",
    "shifts": "Shift",
    "warehouses": "Gudang",
    "companies": "Perusahaan",
    "operational": "Operasional",
    "allStoreSales": "Penjualan Semua Toko"
  },
  "shared": {
    "columns": {
      "hasAllAccess": "Akses Semua Fitur",
      "id": "ID",
      "balance": "Saldo",
      "action": "Aksi",
      "name": "Nama",
      "shortName": "Nama Pendek",
      "email": "Email",
      "username": "Username",
      "phone": "No. HP",
      "barcode": "Barcode",
      "code": "Kode",
      "category": "Kategori",
      "subCategory": "Sub Kategori",
      "description": "Deskripsi",
      "unit": "Satuan",
      "sellPrice": "Harga Jual",
      "salesperson": "Salesperson",
      "commissionPercentage": "Persentase Komisi",
      "commission": "Komisi",
      "finalSellPrice": "Harga Jual Akhir",
      "purchasePrice": "Harga Beli",
      "defaultPrice": "Harga Minimal",
      "cost": "Ongkos",
      "fee": "Biaya",
      "makeIt": "Jadikan",
      "profit": "Keuntungan",
      "priceCategory": "Kat. Harga",
      "invoice": "Invoice",
      "deliveryOrder": "Surat Jalan",
      "deliveryOrderNumber": "No. Surat Jalan",
      "invoiceNumber": "No. Invoice",
      "voucherNumber": "No. Voucher",
      "invoicePrefix": "Kode Awalan Invoice",
      "taxInvoiceNumber": "No. Faktur Pajak",
      "identityNumber": "No. Identitas",
      "date": "Tanggal",
      "startDate": "Dari Tanggal",
      "endDate": "Ke Tanggal",
      "invoiceDate": "Tanggal Faktur",
      "dueDate": "Tanggal Jatuh Tempo",
      "reportDate": "Tanggal Masa",
      "receiveDate": "Tanggal Terima",
      "quantity": "Kuantitas",
      "checkedQuantity": "Qty Diperiksa",
      "receivedQuantity": "Qty Diterima",
      "returnQuantity": "Qty Retur",
      "returnQuantityOld": "Return Qty Lama",
      "returnQuantityNew": "Return Qty Baru",
      "deliveryOrderQuantity": "Surat Jalan Qty",
      "outstandingQuantity": "Qty Kurang",
      "currentQuantity": "Qty Sekarang",
      "stockName": "Nama Stok",
      "newStock": "Stock Baru",
      "stockBefore": "Stock Sebelum",
      "stockAfter": "Stock Sesudah",
      "store": "Toko",
      "stock": "Stok",
      "stockAlert": "Pemberitahuan Stok",
      "currentStock": "Stok",
      "supplier": "Pemasok",
      "customer": "Pelanggan",
      "item": "Barang",
      "totalItem": "Jumlah Barang",
      "basePrice": "Harga Dasar",
      "itemGroup": "Grup Barang",
      "amount": "Nilai",
      "invoiceAmount": "Nilai Faktur",
      "serviceAmount": "Nilai Jasa",
      "warehouse": "Gudang",
      "warehouseFrom": "Gudang Asal",
      "warehouseTo": "Gudang Tujuan",
      "quantityFrom": "Kuantitas Dari",
      "quantityTo": "Kuantitas Sampai",
      "password": "Kata Sandi",
      "repeatPassword": "Ulangi Kata Sandi",
      "paidAmount": "Nilai Terbayar",
      "returnedAmount": "Nilai Retur",
      "outstandingAmount": "Nilai Belum Terbayar",
      "required": "Wajib Isi",
      "price": "Harga",
      "status": "Status",
      "account": "Akun",
      "parentAccount": "Induk Akun",
      "paymentAccount": "Akun Pembayaran",
      "paymentGatewayMethod": "Metode Pembayaran",
      "paymentNumber": "Nomor Pembayaran",
      "paymentNumberLink": "Link Pembayaran",
      "paymentReferenceId": "No. Referensi",
      "paymentExpiredDate": "Tanggal Kadaluarsa",
      "paymentStatus": "Status Pembayaran",
      "paymentAmount": "Total Pembayaran",
      "accountNumber": "No. Rekening",
      "accountName": "Nama Akun",
      "accountType": "Jenis Akun",
      "createdAt": "Dibuat Pada",
      "createdBy": "Dibuat Oleh",
      "editedAt": "Diubah Pada",
      "editedBy": "Diubah Oleh",
      "deletedAt": "Dihapus Pada",
      "deletedBy": "Dihapus Oleh",
      "expedition": "Ekspedisi",
      "shippingPrice": "Ongkos Kirim",
      "discount": "Diskon",
      "discountAmount": "Nilai Diskon",
      "discountPercentage": "Diskon (%)",
      "discountValue": "Nilai Diskon",
      "discountValueReturn": "Nilai Diskon Retur",
      "discountedSellPrice": "Harga Jual Setelah Diskon",
      "deliveryStatus": "Status Pengiriman",
      "stockStatus": "Status Stok",
      "deliveredAt": "Terkirim Pada",
      "limitOutstandingAmount": "Batas hutang",
      "whatsapp": "Whatsapp",
      "otp": "OTP",
      "tax": "Pajak",
      "taxReturn": "Pajak Retur",
      "taxExpedition": "Pajak Ekspedisi",
      "saleLowerPricePermission": "Harga lebih rendah",
      "whatsappNumber": "No. Whatsapp",
      "isWhatsappGroup": "Nomor Merupakan Grup WA",
      "role": "Role",
      "permission": "Hak Akses",
      "year": "Tahun",
      "canceled": "Dibatalkan",
      "ordered": "Dipesan",
      "pic": "PIC",
      "entry": "Entri",
      "address": "Alamat",
      "policeNumber": "Nomor Polisi",
      "source": "Sumber",
      "destination": "Tujuan",
      "isSellable": "Dapat Dijual",
      "isBaseUnit": "Satuan Dasar",
      "fileName": "Nama File",
      "printingPreference": "Preferensi Pencetakan",
      "sale": "Penjualan",
      "numberOfPage": "Jumlah Halaman",
      "numberOfPrintedPages": "Jumlah Halaman Tercetak",
      "numberOfPrintedCopies": "Jumlah Salinan Tercetak",
      "printedAt": "Dicetak Pada",
      "barcodeImage": "Gambar Barcode",
      "select": "Pilih",
      "numberOfCopies": "Jumlah Salinan",
      "pin": "PIN",
      "sn": "Serial Number",
      "monthlysalary": "Gaji Bulanan",
      "overtimehourlysalary": "Gaji Lembur per Jam",
      "bonussalary": "Bonus",
      "latesalarydeduction": "Potongan Terlambat",
      "lateminuteinterval": "Interval Menit Terlambat",
      "minute": "Menit",
      "hour": "Jam",
      "dailysalary": "Gaji Harian",
      "shift": "Shift",
      "dayOfWeek": "Hari Kerja",
      "month": "Bulan",
      "debit": "Debit",
      "credit": "Kredit",
      "memo": "Memo",
      "module": "Module",
      "time": "Jam",
      "startTime": "Jam Masuk",
      "endTime": "Jam Keluar",
      "totalWorkHour": "Total Jam Kerja",
      "overtimeAdjustmentHour": "Jam Penyesuaian Lembur",
      "checkIn": "Masuk",
      "checkOut": "Keluar",
      "workHour": "Jam Kerja",
      "accumulation": "Akumulasi",
      "absent": "Absen",
      "present": "Masuk",
      "late": "Terlambat",
      "overtime": "Lembur",
      "isOnVacation": "Sedang liburan",
      "isOvertimeApproved": "Status Lembur",
      "paidDailySalary": "Gaji Harian Dibayar",
      "company": "Perusahaan",
      "isIntangible": "Tidak Berwujud",
      "yearlyDepreciationPercentage": "Penyusutan Tahunan (%)",
      "assetType": "Jenis Aset",
      "assetDepreciation": "Penyusutan Aset",
      "dateOfUse": "Tanggal Pemakaian",
      "purchaseDate": "Tanggal Pembelian",
      "ageInMonth": "Usia Dalam Bulan",
      "accumulatedDepreciationAccount": "Akun Akumulasi Penyusutan",
      "depreciationExpenseAccount": "Akun Beban Penyusutan"
    },
    "buttons": {
      "back": "Kembali",
      "create": "Tambah",
      "payment": "Pembayaran",
      "submit": "Simpan",
      "reset": "Atur Ulang",
      "cancel": "Batal",
      "show": "Lihat",
      "delete": "Hapus",
      "add": "Tambah",
      "edit": "Ubah",
      "detail": "Detail",
      "confirm": "Konfirmasi",
      "close": "Tutup",
      "yes": "Ya",
      "no": "Tidak",
      "login": "Masuk",
      "receive": "Terima Barang",
      "send": "Kirim",
      "transfer": "Mutasi",
      "adjustments": "Ubah",
      "return": "Retur",
      "history": "Riwayat",
      "notify": "Kirim Pemberitahuan",
      "getPrinters": "Get Printers",
      "copy": "Salin",
      "print": "Cetak",
      "printAll": "Cetak Semua",
      "testPrint": "Tes Cetak",
      "purchaseInvoice": "Faktur Pembelian",
      "download": "Unduh",
      "updateSales": "Update Penjualan",
      "updateCustomer": "Update Pelanggan",
      "outOfStock": "Habis",
      "paymentGateway": "Payment Gateway"
    },
    "inputs": {
      "search": "Cari"
    },
    "labels": {
      "columns": "Kolom",
      "confirmDelete": "Apakah Anda yakin menghapus data ini?",
      "confirmCancel": "Apakah Anda yakin membatalkan data ini?",
      "noData": "Tidak ada data",
      "noSearchResut": "Pencarian data tidak ditemukan",
      "required": "Data wajib diisi",
      "date": "Tanggal tidak sesuai",
      "positiveNumber": "Angka tidak sesuai",
      "nonNegativeNumber": "Angka tidak dapat negatif",
      "noCommaNumber": "Angka tidak dapat koma",
      "notFound": "Tidak ada data ditemukan",
      "success": "Berhasil",
      "failed": "Gagal",
      "subTotalPrice": "Sub Total Harga",
      "totalPrice": "Total Harga",
      "totalReturnAmount": "Total Retur",
      "afterTax": "Setelah Pajak",
      "tax": "Pajak",
      "settled": "Lunas",
      "paid": "Dibayar",
      "outstanding": "Hutang",
      "downPayment": "DP",
      "addPayment": "Tambah Pembayaran",
      "addExpedition": "Tambah Ekspedisi",
      "paymentMethod": "Pembayaran",
      "list": "Daftar",
      "maxValue": "Maksimal ",
      "minValue": "Minimal ",
      "maxDiscount": "Maximal 99%",
      "qtyReceivingMaxValue": "Qty Diterima melebihi qty barang",
      "qtyEmpty": "Qty masih kosong",
      "exceedStock": "Melebihi permintaan stok barang",
      "totalSales": "Total Penjualan",
      "totalDefaultPrice": "Total Harga Minimal",
      "totalFinalSellPrice": "Total Harga Jual Akhir",
      "barcodeDefaultSameAsCode": "Jika barcode kosong akan disamakan dengan kode",
      "completeForm": "Form Lengkap",
      "includeTax": "Termasuk Pajak",
      "excludeTax": "Tidak Termasuk Pajak",
      "oneItemPriceCheaper": "Salah satu harga barangnya lebih murah dari min. harga",
      "request": "Meminta",
      "verify": "Memeriksa",
      "resend": "Kirim Ulang",
      "timeRemaining": "Waktu yang tersisa",
      "sendAll": "Kirim Semua",
      "default": "Dasar",
      "fileName": "Nama File",
      "ipAddress": "IP Address",
      "printerName": "Nama Printer",
      "paperSize": "Ukuran Kertas",
      "orientation": "Orientasi",
      "color": "Warna",
      "copies": "Salinan",
      "collate": "Menyusun",
      "deleteFile": "Hapus File",
      "wrongInput": "Input data is incorrect",
      "onPrinting": "Sedang Mencetak",
      "printed": "Tercetak",
      "notPrintedYet": "Belum Tercetak",
      "inputDataCustomer": "Masukan Data Pelanggan",
      "day": "Hari",
      "day1": "Minggu",
      "day2": "Senin",
      "day3": "Selasa",
      "day4": "Rabu",
      "day5": "Kamis",
      "day6": "Jumat",
      "day7": "Sabtu",
      "done": "Selesai",
      "pending": "Pending",
      "detail": "Detail",
      "day": "Hari",
      "expiredDay": "Batas Waktu",
      "notifications" : {
        "sales" : "Penjualan",
        "purchase" : "Pemesanan",
        "stock" : "Stok",
        "sameForAll" : "Samakan Semua"
      },
      "all": "Semua",
      "allocate": "Alokasi",
      "commission": "Komisi",
      "byWarehouse": "Per Gudang"
    },
    "notifications": {
      "loginSuccess": "Berhasil login",
      "logoutSuccess": "Berhasil keluar dari sistem",
      "deleted": "Data berhasil dihapus",
      "storeChanged": "Berhasil mengubah toko",
      "warehouseChanged": "Berhasil mengubah gudang",
      "finalSellPriceUpdated": "Berhasil mengubah Harga Jual Akhir",
      "unauthorized": "Tidak dapat mengakses halaman ini",
      "repeatPasswordShouldBeSame": "Input ulangi kata sandi harus sama dengan kata sandi",
      "itemUnitThereMustBeActiveUnit": "Satuan barang harus ada satuan yang aktif",
      "getPrinterSuccess": "Berhasil mendapatkan data printer",
      "getPrinterFailed": "Berhasil mendapatkan data printer",
      "setPrinterSuccess": "Setting printer berhasil",
      "setPrinterFailed": "Setting printer gagal",
      "printerNotSetup": "Printer belum diatur",
      "connectionTimeout": "Waktu koneksi habis",
      "addressUnreachable": "Alamat tidak dapat dijangkau",
      "printingSuccess": "Berhasil mencetak",
      "printingFailed": "Gagal mencetak",
      "dataHasBeenAdded": "Data telah ditambahkan",
      "addDeliveryOrderAlertDialog": "Data pelanggan belum diisi.",
      "cannotDeleteItem": "Tidak dapat menghapus barang",
      "copied": "Berhasil disalin",
      "failedToCopy": "Gagal disalin"
    },
    "permissions": {
      "view": "Lihat",
      "create": "Tambah",
      "update": "Ubah",
      "delete": "Hapus",
      "cancel": "Batal",
      "return": "Retur",
      "viewHistorySaleItem": "Lihat riwayat penjualan barang",
      "viewHistoryPurchaseItem": "Lihat riwayat pembelian barang",
      "addPayment": "Tambah pembayaran",
      "printInvoice": "Cetak invoice",
      "printDeliveryOrder": "Cetak surat jalan",
      "receiveItem": "Terima barang",
      "filterDate": "Filter tanggal",
      "filterYear": "Filter tahun",
      "sendNotification": "Kirim pemberitahuan",
      "process": "Proses"
    }
  },
  "modules": {
    "assets": {
      "title": "Aset"
    },
    "assetDepreciations": {
      "title": "Penyusutan Aset"
    },
    "assetTypes": {
      "title": "Jenis Aset"
    },
    "items": {
      "title": "Barang",
      "basePrice": "Harga Dasar Barang"
    },
    "itemStocks": {
      "title": "Stok Barang"
    },
    "itemStockAdjustments": {
      "title": "Ubah Stok Barang"
    },
    "itemUnits": {
      "title": "Satuan Barang"
    },
    "itemDeliveries": {
      "title": "Pengiriman Barang"
    },
    "categories": {
      "title": "Kategori"
    },
    "subCategories": {
      "title": "Sub Kategori"
    },
    "warehouses": {
      "title": "Gudang"
    },
    "suppliers": {
      "title": "Pemasok"
    },
    "purchases": {
      "title": "Pembelian"
    },
    "purchaseItems": {
      "title": "Pembelian Barang"
    },
    "purchaseReceivings": {
      "title": "Penerimaan Barang"
    },
    "purchasesList": {
      "title": "Daftar Pembelian"
    },
    "customers": {
      "title": "Pelanggan"
    },
    "sales": {
      "title": "Penjualan"
    },
    "saleItems": {
      "title": "Penjualan Barang"
    },
    "salesList": {
      "title": "Penjualan"
    },
    "saleGroups": {
      "title": "Penjualan Group"
    },
    "priceCheck": {
      "title": "Cek Harga"
    },
    "stockCheckings": {
      "title": "Pengecekan Stok"
    },
    "purchaseReturns": {
      "title": "Retur Pembelian"
    },
    "purchaseReturnItems": {
      "title": "Barang Retur Pembelian"
    },
    "saleReturns": {
      "title": "Retur Penjualan"
    },
    "saleReturnItems": {
      "title": "Barang Retur Penjualan"
    },
    "stores": {
      "title": "Toko"
    },
    "shifts": {
      "title": "Shift"
    },
    "units": {
      "title": "Satuan"
    },
    "priceCategories": {
      "title": "Kategori Harga"
    },
    "accountTypes": {
      "title": "Tipe Akun",
      "name": "Nama Tipe Akun"
    },
    "accounts": {
      "title": "Akun",
      "name": "Nama Akun",
      "nameParents": "Nama Induk Akun"
    },
    "defaultAccounts": {
      "title": "Akun Dasar"
    },
    "expeditions": {
      "title": "Ekspedisi"
    },
    "serviceTypes": {
      "title": "Tipe Jasa"
    },
    "warehouseReceivings": {
      "title": "Penerimaan Barang Gudang"
    },
    "adminReceivings": {
      "title": "Penerimaan Barang Admin"
    },
    "stockTransfers": {
      "title": "Mutasi Stok Gudang"
    },
    "stockAdjustments": {
      "title": "Ubah Stok"
    },
    "users": {
      "title": "Pengguna"
    },
    "userRoles": {
      "title": "Role Pengguna"
    },
    "roles": {
      "title": "Role"
    },
    "rolePermissions": {
      "title": "Role Permissions"
    },
    "salespersons": {
      "title": "Salesperson"
    },
    "salespersonCommissions": {
      "title": "Salesperson Komisi"
    },
    "saleReports": {
      "title": "Laporan Penjualan"
    },
    "stockAlerts": {
      "title": "Peringatan Stok"
    },
    "outstandingSales": {
      "title": "Penjualan Belum Lunas"
    },
    "purchaseReports": {
      "title": "Laporan Pembelian"
    },
    "outstandingPurchases": {
      "title": "Pembelian Belum Lunas"
    },
    "cashBanking": {
      "title": "Kas Bank"
    },
    "generalJournals": {
      "title": "Jurnal Umum"
    },
    "generalJournalEntries": {
      "title": "Entri Jurnal Umum"
    },
    "ledger": {
      "title": "Buku Besar"
    },
    "deposits": {
      "title": "Penerimaan"
    },
    "expenses": {
      "title": "Pembayaran"
    },
    "printerSettings": {
      "title": "Pengaturan Printer"
    },
    "printBarcodes": {
      "title": "Cetak Barcode"
    },
    "employees": {
      "title": "Karyawan"
    },
    "shiftSchedules": {
      "title": "Jadwal Shift"
    },
    "salaries": {
      "title": "Gaji"
    },
    "deliveryOrders": {
      "title": "Surat Jalan"
    },
    "attendances": {
      "title": "Kehadiran"
    },
    "permissions": {
      "title": "Izin Akses"
    },
    "expeditionTransactions": {
      "title": "Transaksi Ekspedisi"
    },
    "companies" : {
      "title" : "Perusahaan"
    },
    "salesTax": {
      "title": "Penjualan PPN"
    },
    "salesMerchandiseInventory": {
      "title": "Penjualan Persediaan Dagang"
    },
    "salesAccountReceivable": {
      "title": "Penjualan Piutang Usaha"
    },
    "salesCostOfGoodSold": {
      "title": "Penjualan COGS"
    },
    "purchasesTax": {
      "title": "Pembelian PPN"
    },
    "purchasesDebt": {
      "title": "Pembelian Hutang Usaha"
    },
    "purchasesDebtTax": {
      "title": "Pembelian Hutang Usaha PPN"
    },
    "expeditionsTax": {
      "title": "Ekspedisi PPN"
    },
    "expeditionsDebt": {
      "title": "Ekspedisi Hutang Usaha"
    },
    "expeditionsDebtTax": {
      "title": "Ekspedisi Hutang Usaha PPN"
    },
    "saleJurnal": {
      "title": "Penjualan"
    },
    "saleTransaction": {
      "title": "Penjualan"
    },
    "saleReturnJurnalIn": {
      "title": "Retur Penjualan Masuk"
    },
    "saleReturnJurnalOut": {
      "title": "Retur Penjualan Keluar"
    },
    "saleReturnTransactionIn": {
      "title": "Retur Penjualan Masuk"
    },
    "saleReturnTransactionOut": {
      "title": "Retur Penjualan Keluar"
    },
    "purchaseJurnal": {
      "title": "Pembelian"
    },
    "purchaseTransaction": {
      "title": "Pembelian"
    },
    "purchaseReturnJurnalIn": {
      "title": "Retur Pembelian Masuk"
    },
    "purchaseReturnJurnalOut": {
      "title": "Retur Pembelian Keluar"
    },
    "purchaseReturnTransactionIn": {
      "title": "Retur Pembelian Masuk"
    },
    "purchaseReturnTransactionOut": {
      "title": "Retur Pembelian Keluar"
    },
    "expeditionJurnal": {
      "title": "Ekspedisi"
    },
    "expeditionTransaction": {
      "title": "Ekspedisi"
    },
    "salaryJurnal": {
      "title": "Gaji"
    },
    "salaryTransaction": {
      "title": "Gaji"
    }
  }
}